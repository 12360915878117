/******************** activation ********************/
input, textarea {
  background-color: #FFFFFF;
  width: 100%;
  box-sizing: border-box;
  border: none !important;
  border: 1px solid #933CAC !important;
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 400;
}


input:focus, textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #EC008C !important;
  outline-width: 0;
  font-weight: 400;
}

button:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
}



.card1 {
  width: 75%;
  
}
.card3 {
  width: 50%;
 
}


.card2 {
  width:50%;
  
}

#logo {
  width: 70px;
  height: 60px;
}

.heading {
  margin-bottom: 60px !important;
}

::placeholder {
  color: #000 !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #000 !important;
}

::-ms-input-placeholder {
  color: #000 !important;
}

.form-control-label {
  font-size: 15px;
  margin-left: 15px;
}

.msg-info {
  padding-left: 15px;
  margin-bottom: 30px;
}

.btn-color {
  border-radius: 50px;
  color: #fff;
  background-image: linear-gradient(90deg, #00A1E4, #2E81D2, #933CAC, #D31095, #EC008C);
  padding: 15px;
  cursor: pointer;
  border: none !important;
  margin-top: 40px;
}
.btn-color-disable {
  border-radius: 50px;
  color: #fff;
  background: linear-gradient(90deg, 	#6C757D,	#6C757D,	#6C757D,	#6C757D,	#6C757D);
  padding: 15px;
  cursor: pointer;
  border: none !important;
  margin-top: 40px;
}

.btn-color:hover {
  color: #fff;
  background-image: linear-gradient(90deg, #00A1E4, #2E81D2, #933CAC, #D31095, #EC008C);
}

.btn-white {
  border-radius: 50px;
  color: #D500F9;
  background-color: #fff;
  padding: 8px 40px;
  cursor: pointer;
  border: 2px solid #D500F9 !important;
}

.btn-white:hover {
  color: #fff;
  background-image: linear-gradient(90deg, #00A1E4, #2E81D2, #933CAC, #D31095, #EC008C);
}

a {
  color: #000;
}

a:hover {
  color: #000;
}

.bottom {
  width: 100%;
  margin-top: 30px !important;
}

.sm-text {
  font-size: 15px;
}

@media screen and (max-width: 992px) {
  .card1 {
      width: 100%;
      
  }

  .card2 {
      width: 100%;
  }
  .card3 {
      width: 100%;
      
  }

  .right {
      margin-top: 100px !important;
      margin-bottom: 100px !important;
  }
}

@media screen and (max-width: 768px) {
  .container {
      padding: 10px !important;
  }

  .right {
      margin-top: 50px !important;
      margin-bottom: 50px !important;
  }
}
.card-back {
  background: linear-gradient(90deg, #00A1E4, #2E81D2, #933CAC, #D31095, #EC008C);
}
.card-bg {
background: linear-gradient(90deg, #933CAC,#933CAC);

}
.card-back-info {
  background: linear-gradient(90deg, #933CAC,#933CAC);
  
}

.homeText {
  color:#933CAC;;
}

.cardButton {
  background: linear-gradient(90deg, #00A1E4, #2E81D2, #933CAC, #D31095, #EC008C);
  width: 200px;
  height:auto;
  box-shadow: 0 0 10px 0 rgba(100,100,100,1)
}
.saloonButton {
  background: linear-gradient(90deg, #933CAC,  #933CAC);
  width: auto;
  height:auto;
}
.panelButton {
  background: linear-gradient(90deg, #933CAC,  #933CAC);
  width: 150px;
  height:auto;
}
.saloonButtonX {
  background: linear-gradient(90deg, #008000 ,#008000);
  width: auto;
  height:auto;
}
.saloonButtonY {
  background: linear-gradient(90deg, #FF0000 ,#FF0000);
  width: auto;
  height:auto;
}

.soldButton {
  background: linear-gradient(90deg, #ccc, #ccc, #ccc, #ccc, #ccc);
  width: 200px;
  height:auto;
}

.headTittle {
  font-weight: bold;
}
@import "react-image-gallery/styles/css/image-gallery.css";

.btns{
  background: #8546b3 ;
  border: none;
  border-radius: 10px;
  
}
.icons{
  color: white;
  font-size:x-large;
}

.title{
  color:  #8546b3 ;
  font-weight: bold;
}
.footLink {
  color: #ffffff;
  text-decoration: none;
}

.foot {
  background: linear-gradient(90deg, #00A1E4, #2E81D2, #933CAC, #D31095, #EC008C);
}
.footRight {
 text-align: end;
}
.btn1-color {
  
     
  background: linear-gradient(90deg, #00A1E4, #2E81D2, #933CAC, #D31095, #EC008C);

}
.btn2-color {
  
     
  background: linear-gradient(90deg, 	#6C757D,	#6C757D,	#6C757D,	#6C757D,	#6C757D);

}

.navLink {
  color: #000;
}

.headerInput, textarea {
  background-color: #FFFFFF;
  padding: 7px !important;
  width: 100%;
  box-sizing: border-box;
  border: none !important;
  border: 1px solid #F3E5F5 !important;
  font-size: 16px !important;
  color:#000 !important;
  font-weight: 400;
  border-radius: 5px;
}


.headerInput:focus, textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #D500F9 !important;
  outline-width: 0;
  font-weight: 400;
}

.citySelected, textarea {
  background-color: #FFFFFF;
  border-radius: 5px !important;
  padding: 9px !important;
  width: 100%;
  box-sizing: border-box;
  border: none !important;
  border: 1px solid #F3E5F5 !important;
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 400;
}
.citySelected:focus, textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #D500F9 !important;
  outline-width: 0;
  font-weight: 400;
}
.dropdown-menu {
max-height: 280px;
overflow-y: auto;
}
.overlay {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;

}

.wrapper{
  position: relative;
  
}
  
.wrapper-cart{
  position: absolute;
  left: 30%;
  top: -30%;
}

.category-group>.row {
  display: block;
  overflow-x: auto;
  white-space: nowrap; 
}

.category-group>.row>.col-2 {
  display: inline-block;
}

.category-element {
  padding: 5px 5px;
  text-align: center;
}

.category-element-img {
  max-width:100%;
  border: 2px solid #933CAC ; /* Gray border */
  border-radius: 10px;  /* Rounded border */
}

.category-element-img-select {

  max-width:100%;
  border: 2px solid #EC008C; /* Gray border */
  border-radius: 10px;  /* Rounded border */
}

.category-element-p {
  margin-top: 10px;
  font-size: 10px;
  text-align: center;
}
::-webkit-scrollbar{
  width: 0;
  background:transparent
}

.containerIframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 118%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.rotated {
  transform: rotate(45deg); /* Equal to rotateZ(45deg) */
}
.box {
  display: flex;
 
  min-width: auto;
  text-align: center;
  rotate: 90deg;
  justify-content: center;
  align-items: center;
  align-self: center;
  
}

.bg { 
  min-height: 100%;

 
 position:absolute
}

.bg2 { 
 top: 25%;
 position: sticky;
}
.multi-line-text-truncate {
  overflow: hidden;
  max-width: 400px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}



.seat-map {
  border: 1px solid #000;
  padding: 10px;
  background-color: #f9f9f9;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  flex-direction: column;
}

.seat-map-ticket {
  
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.seat-row {
  display:flex;
}

.seat-row-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin: 5px;
}

.seat {
  border: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  position: relative;
}

.seat2 {
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  position: relative;
}

.inactiveSeat {
  padding: 5px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  position: relative;
}

.chartSeat {
  margin: 0px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  position: relative;
  white-space: nowrap;

  
}

.chartSeatStanding {
 
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  white-space: nowrap;

  /* Sabit genişlik ve yükseklik */
  width: 5px; /* Tüm koltukların sabit genişliği */
  height: 5px; /* Tüm koltukların sabit yüksekliği */
  min-width: 10px;
  min-height: 10px;

  /* Yazı boyutu */
  font-size: 10px; /* Tüm koltukların sabit yazı boyutu */
}


.seat-label {
  width: 100%;
  text-align: center;
  border: none;
  background: none;
}

.seat select {
  width: 100%;
  margin-bottom: 5px;
  font-size: 12px;
}

.available {
  background-color: #fff;
}

.selected {
  background-color: #fff;
}

.reserved {
  background-color: #d9534f;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .seat {
    width: 50px;
    height: 50px;
    padding: 3px;
  }

  .seat2 {
    width: 50px;
    height: 50px;
    padding: 3px;
  }

  .inactiveSeat {
    width: 50px;
    height: 50px;
    padding: 3px;
  }

  .seat-row-label {
    width: 50px;
    height: 50px;
  }

  .seat select {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .seat {
    width: 40px;
    height: 40px;
    padding: 2px;
  }

  .seat2 {
    width: 40px;
    height: 40px;
    padding: 2px;
  }

  .inactiveSeat {
    width: 40px;
    height: 40px;
    padding: 2px;
  }


  .seat-row-label {
    width: 40px;
    height: 40px;
  }

  .seat select {
    font-size: 8px;
  }
}

.select-container {
  display: flex;
  flex-direction: column;
}


.PopoverContent {
border-radius: 4px;
padding: 10px;
width: 260px;
background-color: white;
box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
animation-duration: 400ms;
animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
will-change: transform, opacity;
z-index: 5;
}
.PopoverContent:focus {
box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px,
  0 0 0 2px var(--violet-7);
}
.PopoverContent[data-state='open'][data-side='top'] {
animation-name: slideDownAndFade;
}
.PopoverContent[data-state='open'][data-side='right'] {
animation-name: slideLeftAndFade;
}
.PopoverContent[data-state='open'][data-side='bottom'] {
animation-name: slideUpAndFade;
}
.PopoverContent[data-state='open'][data-side='left'] {
animation-name: slideRightAndFade;
}

.PopoverContent2 {
  border-radius: 4px;
  padding: 10px;
  width: 380px;
  background-color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 5;
  }
  .PopoverContent2:focus {
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px,
    0 0 0 2px var(--violet-7);
  }
  .PopoverContent2[data-state='open'][data-side='top'] {
  animation-name: slideDownAndFade;
  }
  .PopoverContent2[data-state='open'][data-side='right'] {
  animation-name: slideLeftAndFade;
  }
  .PopoverContent2[data-state='open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
  }
  .PopoverContent2[data-state='open'][data-side='left'] {
  animation-name: slideRightAndFade;
  }

.PopoverArrow {
  fill: #FFFFFF;
  width: 15px;  /* Okun genişliği */
  height: 15px; /* Okun yüksekliği */
  transform: scale(1); /* Okun boyutunu orantılı büyütür */
}

.PopoverClose {
border-radius: 100%;
height: 30px;
width: 30px;
display: inline-flex;
align-items: center;
justify-content: center;
position: absolute;
top: 0px;
right: 0px;
color: #933CAC;

/* Varsayılan olarak sıfır border ve background */
border: none;
background: transparent;



cursor: pointer; /* İkon üzerinde fare ile üzerine gelindiğinde işaretçi */
}
.PopoverClose:hover {
background-color: var(--violet-4);
}
.PopoverClose:focus {
box-shadow: 0 0 0 2px var(--violet-7);
}

.IconButton {
font-family: inherit;
border-radius: 100%;
height: 35px;
width: 35px;
display: inline-flex;
align-items: center;
justify-content: center;
color: var(--violet-11);
background-color: white;
box-shadow: 0 2px 10px var(--black-a7);
}
.IconButton:hover {
background-color: var(--violet-3);
}
.IconButton:focus {
box-shadow: 0 0 0 2px black;
}

.Fieldset {
display: flex;
gap: 20px;
align-items: center;
}

.Label {
font-size: 13px;
color: var(--violet-11);
width: 75px;
}

.Input {
width: 100%;
display: inline-flex;
align-items: center;
justify-content: center;
flex: 1;
border-radius: 4px;
padding: 0 10px;
font-size: 13px;
line-height: 1;
color: var(--violet-11);
box-shadow: 0 0 0 1px var(--violet-7);
height: 25px;
}
.Input:focus {
box-shadow: 0 0 0 2px var(--violet-8);
}

.Text {
margin: 0;
color: var(--mauve-12);
font-size: 15px;
line-height: 19px;
font-weight: 500;
}

@keyframes slideUpAndFade {
from {
  opacity: 0;
  transform: translateY(2px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
}

@keyframes slideRightAndFade {
from {
  opacity: 0;
  transform: translateX(-2px);
}
to {
  opacity: 1;
  transform: translateX(0);
}
}

@keyframes slideDownAndFade {
from {
  opacity: 0;
  transform: translateY(-2px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
}

@keyframes slideLeftAndFade {
from {
  opacity: 0;
  transform: translateX(2px);
}
to {
  opacity: 1;
  transform: translateX(0);
}
}


.edit-icon {
  top: -5px;
  left: -5px;
}

.delete-icon {
  top: -5px;
  right: -5px;
}

.custom-radio:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.2s;
}

.custom-radio:checked:before {
  background-color: #933CAC;
}
.stage-seat {
  background-color: #2E81D2 !important; /* Altın renk */
  border: 2px solid #B8860B; /* Daha koyu altın tonunda bir kenarlık */
}

.standing-seat {
  background-color: #EC008C !important; /* Altın renk */
  border: 2px solid #B8860B; /* Daha koyu altın tonunda bir kenarlık */
}

.shadow {
  box-shadow: 0 0 10px 0 rgba(100,100,100,1)
}


.expandable-container {
  padding: 15px;
  background-color: #f1f1f1;
  border-radius: 0.25rem;
}

.expandable-container h5,
.expandable-container p {
  margin: 5px 0;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-normal {
  font-weight: normal;
}
.text-ellipsis {
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}

.slider-container {
width: 100%; /* Kapsayıcı genişliği %100 yap */
max-width: 1920px; /* Maksimum genişlik */
margin: 0 auto; /* Ortalamak için */
}

.slider-image {
width: 100%; /* Resim genişliğini %100 yap */
height: auto; /* Yüksekliği otomatik ayarla *
max-height: 350px; /* Maksimum yükseklik 350px */
object-fit: cover; /* Resmi kapsayıcıya göre kırp */
}

.sliderButton {
background: linear-gradient(90deg, #933CAC,  #933CAC);
width: 120px;
height:auto;
}

.sliderButton1 {
background: linear-gradient(90deg, #933CAC,  #933CAC);
width: 200px;
height:auto;
}

.sliderButton2 {
background: linear-gradient(90deg, #933CAC,  #933CAC);
width: 180px;
height:auto;
}

.form-check-input:checked {
  background-color: #933CAC !important; /* Aktifken mor arka plan rengi */
  border-color: #933CAC !important; /* Kenarlık rengini de aynı yap */
}

.reportText {
color:#000;
font-size:medium;
}

.custom-checkbox:checked {
accent-color: #933CAC;
}

.seat-empty {
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  position: relative;
}

.sort-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 30px;
  margin: 5px;
  align-self: flex-start; /* Sola yaslama */
}

.priceInput, textarea {
  background-color: #FFFFFF;
  width: 100%;
  box-sizing: border-box;
  border: none !important;
  border: 1px solid #933CAC !important;
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 400;
}


.priceInput:focus, textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #933CAC !important;
  outline-width: 0;
  font-weight: 400;
}


/* Mobilde görsel boyutu küçük tutulur */
.activity-photo {
  max-height: 80px;
  object-fit: cover;
}

/* Tablet ve masaüstü cihazlarda görsel boyutu büyütülür */
@media (min-width: 768px) {
  .activity-photo {
    max-height: 120px;
  }
}

@media (min-width: 992px) {
  .activity-photo {
    max-height: 150px;
  }
}

@media (min-width: 1200px) {
  .activity-photo {
    max-height: 180px;
  }
}

/* support-page sınıfına özgü stiller */
.support-page .card-back {
  height: 100%; /* Kartların boyutlarını eşitlemek için */
}

.support-page .card {
  display: flex;
  flex-direction: column;
  height: 100%; /* Kartları dikey olarak hizalayacak */
}

.support-page .card-body {
  flex-grow: 1; /* İçeriğin büyümesini sağlar */
}

.support-page img {
  object-fit: cover; /* Resmin düzgün görünmesini sağlar */
}
.support-link {
  color: #933cac; /* Link rengi */
  text-decoration: none; /* Altını çizmez */
}

.support-link:hover {
  text-decoration: underline; /* Hover durumunda altını çizebilir */
}

.button-fa-size {
  width:40px;
  height:40px;
}

.icon-fa-size {
  display: flex;
  justify-content: center;
}
.leftLoveSeat {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 3px solid #EC008C; /* Daha koyu altın tonunda bir kenarlık */
  border-right: none; /* Sağ kenarlığı kaldırır */
}

.rightLoveSeat {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 3px solid #EC008C; /* Daha koyu altın tonunda bir kenarlık */
  border-left: none; /* Sağ kenarlığı kaldırır */
}

.disableSeat {
  border: 3px solid #2E81D2; /* Daha koyu altın tonunda bir kenarlık */
  border-radius: 10px;
}

.normalSeat {
  border: 3px solid #66696c; /* Daha koyu altın tonunda bir kenarlık */
  border-radius: 10px;
}
.closeSeat {
  border: 3px solid #CDCDCD; /* Daha koyu altın tonunda bir kenarlık */
  border-radius: 10px;
}
.popover-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-top: 2px solid #933CAC;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 3;
}

.popover-container.collapsed {
  height: 120px; /* Kapalıyken görünen yükseklik */
}

.popover-container.expanded {
  height: 400px; /* Açıkken görünen yükseklik */
}

.popover-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
}

.toggle-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.popover-content {
  padding: 10px 20px;
  display: flex;
  flex-wrap: wrap; /* Kartların alt alta düşmesini sağlar */
  
}

.popover-footer {
  text-align: right;
  font-size: 14px;
  color: gray;
}

.icon-size-fa {
  width: 32px;
  height: 32px;
  text-align: center;
  margin-top: -4px;
  margin-bottom: -4px;
}

.selectableSeatBack {
  background-color: #933CAC;
}
.closeSeatBack {
  background-color:#66696c;
}
.soldSeatBack {
  background-color: #a5a7a9;
}
.myBasketSeatBack {
  background-color: #EC008C;
}
.otherBasketSeatBack {
  background-color: #d0a1ef;
}
.reserveSeatBack {
  background-color: #2E81D2;
}

/* Genel Stil Ayarları */
.modern-box {
  width: 30px;
  height: 30px;
  border: 1.5px solid #ddd;
  display: inline-block;
  transition: all 0.3s ease;
}

/* Boş Çerçeve (Normal) */
.modern-box.normal {
  border: 3px solid #66696c;
  border-radius: 5px;
}
.modern-box.disable {
  border: 3px solid #2E81D2;
  border-radius: 5px;
}

/* Mor Dolgu (Seçilebilir) */
.modern-box.selectable {
  background-color: #a855f7;
  border: none;
  border-radius: 5px;
}

.modern-box.close {
  background-color: #66696c;
  border: none;
  border-radius: 5px;
}

.modern-box.sold {
  background-color: #a5a7a9;
  border: none;
  border-radius: 5px;
}

.modern-box.myBasket {
  background-color: #EC008C;
  border: none;
  border-radius: 5px;
}
.modern-box.otherBasket {
  background-color: #d0a1ef;
  border: none;
  border-radius: 5px;
}

.modern-box.reserved {
  background-color: #2E81D2;
  border: none;
  border-radius: 5px;
}

/* Yuvarlatılmış Sol ve Sağ Çerçeveler */
.modern-box.rounded-left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 3px solid #EC008C;
  border-right: none; /* Sağ kenarlığı kaldırır */
  
}

.modern-box.rounded-right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 3px solid #EC008C;
  border-left: none; /* Sağ kenarlığı kaldırır */
  
}

/* Metin Stili */
.modern-text {
  font-size: 12px;
}

/* Hover Efektleri */
.seat:hover {
  transform: scale(1.2);
}

/* Responsive Tasarım */
@media (max-width: 576px) {
  .modern-box {
    width: 25px;
    height: 25px;
  }
  .modern-text {
    font-size: 12px;
  }
}


.custom-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9); /* Arka plan bulanıklığı */
  z-index: 9999;
}

/* Sabit Görsel */
.loader-image-container {
  position: relative;
  z-index: 2;
}

.loader-image {
  width: 150px; /* Görsel boyutunu ayarlayın */
  height: auto;
  animation: spin 2s linear infinite;
}

/* Dönen Oval Loader */
.loader-spinner {
  position: absolute;
  z-index: 1;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-text {
  position: absolute;
  z-index: 1;
  margin-top: 100px; /* Spinner'ın altına boşluk bırakır */
  font-size: 18px;
  color: #933CAC;
  text-align: center;
}

.scrollable-container {
  max-height: 300px; /* İçeriğin maksimum yüksekliği */
  overflow-y: scroll; /* Dikey scrollbar göster */
  overflow-x: hidden; /* Yatay scrollbar kapalı */
  margin-top: 5px;
}

/* Scrollbar Arkaplanı - İnce Çizgi */
.scrollable-container::-webkit-scrollbar {
  width: 10px; /* Scrollbar genişliği */
  background: #ddd; /* Scrollbar arkaplan rengi */
  border-radius: 10px;
}

/* Scrollbar Track - Arka Çizgi */

/* Scrollbar Thumb - Yuvarlak Top */
.scrollable-container::-webkit-scrollbar-thumb {
  background: #933CAC; /* Kaydırıcı rengi */
  border-radius: 10px;
  width: 10px; /* Kaydırıcı genişliği */
  height: 10px; /* Kaydırıcı yüksekliği (Tam yuvarlak için aynı değer) */
}


.seat-map2 {
  border: 1px solid #000;
  padding: 10px;
  background-color: #f9f9f9;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto; /* Taşmaları engelle */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  width: auto; /* Konteyner genişliği */
  height: auto; /* Konteyner yüksekliği */
  min-width: 250px; /* Minimum genişlik */
  min-height: 250px; /* Minimum yükseklik */
}

.bi-arrows-angle-expand {
  font-size: 15px; 
  color: #ffffff;
  background-color: #933CAC; 
  cursor: pointer; 
  transition: transform 0.2s;
  border-radius: 6px;
  border: 2px ; 
}

.card-chipsy {
  border: 1px solid #933CAC;
}

.searchUsername::placeholder {
  color: var(--bs-secondary-color);
  opacity: 0.6;
}

.blinking-text {
  animation: blink 1s infinite alternate;
  display: inline;
}

@keyframes blink {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.headerInput2, textarea {
  background-color: #FFFFFF;
  padding: 7px !important;
  width: 100%;
  box-sizing: border-box;
  border: none !important;
  border: 1px solid #F3E5F5 !important;
  font-size: 16px !important;
  color:#000 !important;
  font-weight: 400;
  border-radius: 5px;
}


.headerInput2 input {
  background-color: #FFFFFF;
  padding: 0px !important;
  box-sizing: border-box;
  border: none !important; /* Tüm kenarlıkları kaldır */
  border-bottom: 1px solid #933CAC !important; /* Sadece alt çizgi */
  font-size: 16px !important;
  color: #000 !important;
  border-radius: 0; /* Kenar yuvarlaklığını kaldır */
}
.headerInput2 input:focus {

  border-bottom: 1px solid #EC008C !important; /* Sadece alt çizgi */
 
}

.btns2{
  background: #8546b3 ;
  border: none;
  border-radius: 10px;
  width: 300px;
}

.form-control-dropdown {
  width: 100%;
  height: 38px; 
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: #fff;
  color: #000;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-color:#933CAC ;
  border-radius: 5px;
}

.form-control-dropdown:hover,
.form-control-dropdown:focus {
  background-color: #fff;
  border-color: #EC008C;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}




